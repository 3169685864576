import React from 'react'
import Image from 'next/image'
import Card from '@/components/Landing/Card'
import useBreakpoints from '@/hooks/useBreakpoints'
import keys from '@/translations/en-us.json'
import styles from './Cards.module.scss'

const Cards = () => {
  const { isSm } = useBreakpoints()

  return (
    <div className={styles.cards}>
      <Card
        className={styles.cloudCard}
        description={keys.cards.cloud.description}
        image={
          <Image
            alt={keys.cards.cloud.alt}
            loading="eager"
            src="landing/assets/cloud.svg"
            {...(isSm ? { fill: true } : { width: 376, height: 375 })}
          />
        }
        title={keys.cards.cloud.title}
      />
      <Card
        className={styles.clockCard}
        description={keys.cards.clock.description}
        image={
          <Image
            alt={keys.cards.clock.alt}
            src="landing/assets/clock.svg"
            {...(isSm ? { fill: true } : { width: 316, height: 316 })}
          />
        }
        isRight
        title={keys.cards.clock.title}
      />
      <Card
        className={styles.lockCard}
        description={keys.cards.lock.description}
        image={
          <Image
            alt={keys.cards.lock.alt}
            src="landing/assets/lock.svg"
            {...(isSm ? { fill: true } : { width: 328, height: 328 })}
          />
        }
        title={keys.cards.lock.title}
      />
      <Card
        className={styles.mapCard}
        description={keys.cards.map.description}
        image={
          <Image
            alt={keys.cards.map.alt}
            src="landing/assets/map.svg"
            {...(isSm ? { fill: true } : { width: 327, height: 326 })}
          />
        }
        isRight
        title={keys.cards.map.title}
      />
    </div>
  )
}

export default Cards
