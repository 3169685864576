import React from 'react'
import classNames from 'classnames'
import styles from './Card.module.scss'

interface TCard {
  className?: string
  description: string | React.ReactNode
  image: React.ReactNode
  isRight?: boolean
  title: string
}

const Card = ({
  className,
  description,
  image,
  isRight = false,
  title,
}: TCard) => {
  const renderImage = () => <div className={styles.cardImage}>{image}</div>

  return (
    <section
      className={classNames(styles.card, {
        [className!]: !!className,
        [styles.right]: isRight,
      })}>
      {isRight && !!image && renderImage()}
      <div
        className={classNames(styles.cardBody, {
          [styles.right]: isRight,
        })}>
        <h2 className={styles.cardTitle}>{title}</h2>
        <p className={styles.cardDescription}>{description}</p>
      </div>
      {!isRight && !!image && renderImage()}
    </section>
  )
}

export default Card
