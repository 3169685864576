import React from 'react'
import Head from 'next/head'
import Home from '@/pages/Home'
import keys from '@/translations/en-us.json'

export default function HomePage() {
  return (
    <>
      <Head>
        <title>{`${keys.common.appName} - ${keys.home.title}`}</title>
        <meta
          content={keys.home.description}
          name="description"
        />
        <meta
          content="website"
          property="og:type"
        />
        <meta
          content={keys.common.appName}
          property="og:title"
        />
        <meta
          content="https://tryjourneyapp.com"
          property="og:url"
        />
        <meta
          content="https://tryjourneyapp.com/graph_image.png"
          property="og:image"
        />
        <meta
          content={keys.home.description}
          property="og:description"
        />
        <meta
          content="app-id=1662059644"
          name="apple-itunes-app"
        />
      </Head>
      <Home />
    </>
  )
}
