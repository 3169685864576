import React from 'react'
import Cards from '@/components/Landing/Cards'
import DownloadSection from '@/components/Landing/DownloadSection'
import Footer from '@/components/Landing/Footer'
import Header from '@/components/Landing/Header'
import Hero from '@/components/Landing/Hero'
import styles from './Home.module.scss'

const Home = () => (
  <section className={styles.container}>
    <Header />
    <Hero />
    <Cards />
    <DownloadSection />
    <Footer />
  </section>
)

export default Home
