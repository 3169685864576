import React, { useState } from 'react'
import DownloadApp from '@/components/Landing/DownloadApp'
import WaitlistForm from '@/components/Landing/WaitlistForm'
import keys from '@/translations/en-us.json'
import styles from './Hero.module.scss'

const Hero = () => {
  const [isWaitlistExpanded, setIsWaitlistExpanded] = useState<boolean>(false)

  const onWaitlistExpandClick = () => setIsWaitlistExpanded(true)

  return (
    <section className={styles.hero}>
      <h1>{keys.hero.title}</h1>
      <p className={styles.description}>{keys.hero.description}</p>
      <div className={styles.downloadApp}>
        <DownloadApp />
        {isWaitlistExpanded ? (
          <WaitlistForm />
        ) : (
          <button
            className={styles.expandButton}
            onClick={onWaitlistExpandClick}
            type="button">
            {keys.hero.requestAccessButton}
          </button>
        )}
      </div>
    </section>
  )
}

export default Hero
